<template>

  <div class="wrapper">

    <Slider></Slider>

    <div>
      <section class="container">
        <div class="row mt-2 mb-0">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 class="text-dark" style="margin-top: 8px;">
              Popular Games
              <sup style="margin-left: 2px">
                <span class="custom-badge">NEW</span>
              </sup>
            </h5>
          </div>
        </div>

        <div class="featured-games-grid justify-content-around">

          <div style="text-align: center;" class="crash-game fly-game">
            <div class="game-icon">
              <router-link to="/">
                <img loading="lazy"
                  style="width: 100%; height: 140px; object-fit: cover; border-radius: 16px; padding: 0; background-color: var(--lightest-gray); border: solid var(--light-gray) 1px;"
                  src="/img/home/sports-book.jpeg" alt="Avatar" />
              </router-link>
            </div>

          </div>


          <div v-for="(i, x) in allgamesdata" v-bind:key="getKey(x)" style="text-align: center;"
            class="crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy" :src="getImg(i.image)" alt="Avatar" data-toggle="modal"
                :data-target="'#casinoGames' + i.game_id"
                style="width: 100%; height: 140px; object-fit: cover; border-radius: 16px; padding: 0; background-color: var(--lightest-gray); border: solid var(--light-gray) 1px;" />

              <div class="random-number">
                <span>
                  <img style="width: 16px;" src="/img/TopNav/user-dark.png" />
                </span>
                {{ getRandomNumber() }}
              </div>
            </div>

            <div class="modal fade" :id="'casinoGames' + i.game_id" tabindex="-1" role="dialog"
              :aria-labelledby="'casinoGamesLabel' + i.game_id" aria-hidden="true">
              <div class="modal-dialog modal-dialog" role="document">
                <div style="color: var(--darkBackground);" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" :id="'casinoGamesLabel' + i.game_id">{{ i.game_name }}</h5>
                    <span type="button" class="close" id="faso-close" data-dismiss="modal">&times;</span>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" style="border-radius: 5px;border: solid var(--light-gray) 1px;" :src="i.image"
                      alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal"
                        style="background-color: var(--darkBackground);color: var(--white);" class="btn ww-100 mb-2"
                        :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: i.provider_id, gameName: i.game_name, gameID: i.game_id, launchURL: i.launch_url, launchType: i.launch_type, launchParameters: i.launch_parameters }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: center;padding: 10px;" class="crash-game fly-game more" v-if="false !== false">
            <div class="game-icon">
              <router-link to="/casino"
                style="background-color: #101B21;color: #96a9de;border: solid #96a9de 1px;border-radius: 9px;"
                class="btn">
                More <img loading="lazy" style="width: 30px;" src="/img/games.png" alt="Avatar" />
              </router-link>
            </div>
          </div>
        </div>

      </section>

    </div>

    <BottomNavigation></BottomNavigation>

  </div>

</template>

<script>
const BottomNavigation = () => import('./BottomNavigation.vue')
const Slider = () => import('./Slider.vue')
import casino from "@/services/casino";

export default {
  name: 'Home',
  data() {
    return {
      aviatorProviderID: 7,
      aviatorGameID: "aviator",
      aviatorGameName: "Aviator",
      aviatorCrashGameUrl: "aviator",
      //
      jetxProviderID: 5,
      jetxGameID: "JetX",
      jetxGameName: "Jet X",
      jetxCrashGameUrl: "jetx",
      //
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      allgamesdata: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        }
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 15,
        "name": "Crash"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
      ],
      homePageGame: [
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
        },
        {
          providerID: 3,
          gameID: "1",
          gameName: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
        {
          providerID: 3,
          gameID: "4",
          gameName: "Taxi Ride",
          image: "/img/home/Taxi.png",
        },
        {
          providerID: 5,
          gameID: "MineIsland",
          gameName: "MineIsland",
          image: "/img/home/MineIsland.png",
        },
        // {
        //   providerID: 1,
        //   gameID: "1301",
        //   gameName: "Spaceman",
        //   image: "/img/home/Spaceman.png",
        // },
        {
          providerID: 4,
          gameID: "rocketman",
          gameName: "Rocket Man",
          image: "/img/home/Rocketman.jpg",
        },
        {
          providerID: 3,
          gameID: "13",
          gameName: "Rock Paper Scissors",
          image: "/img/home/RockPaperScissors.png",
        },
        {
          providerID: 5,
          gameID: "FootballX",
          gameName: "FootballX",
          image: "/img/home/FootballX.png",
        },
        {
          providerID: 5,
          gameID: "Cappadocia",
          gameName: "Cappadocia",
          image: "/img/home/Cappadocia.png",
        },
        {
          providerID: 3,
          gameID: "2",
          gameName: "Gold Rush",
          image: "/img/home/GoldRush.png",
        },
        {
          providerID: 5,
          gameID: "JetX3",
          gameName: "JetX3",
          image: "/img/home/JetX3.png",
        },
        {
          providerID: 3,
          gameID: "14",
          gameName: "Rugby Run",
          image: "/img/home/Rugby.png",
        },
        {
          providerID: 1,
          gameID: "vs20olympgate",
          gameName: "Gates of Olympus",
          image: "/img/home/GatesofOlympus.png",
        },
        {
          providerID: 3,
          gameID: "5",
          gameName: "Monkey Bizniz",
          image: "/img/home/Monkey.png",
        },
      ],

    }
  },
  components: {
    BottomNavigation,
    Slider,
  },
  mounted: function () {
    this.getCasinoGames(this.casinoCategoryActive);

    this.$store.dispatch("setCurrentPage", "home");
    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    getCasinoGames: function (activeCategory) {

      this.loading = true;
      this.casinoCategoryActive = activeCategory;

      var vm = this;

      //var platform = this.isMobile() ? 'mobile' : 'desktop';
      //var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      var path = "/games?page=" + this.currentPage + "&per_page=" + this.perPage + "&category_id=" + activeCategory.id;

      casino.get(path)
        .then((resp) => {

          vm.allgamesdata = resp.data.data;
          vm.allgames = vm.allgamesdata;

          console.log("allgames --> " + JSON.stringify(vm.allgames, undefined, 2));

          this.loading = false;

          window.scrollTo(0, 0);

        });

    },

    getRandomNumber() {
      return Math.floor(Math.random() * (9997 - 50 + 1)) + 50;
    },

    aviatorGetRandomNumber() {
      return Math.floor(Math.random() * (15997 - 50 + 1)) + 50;
    },

    handleGesture: function () {

      var scrollLeft = document.getElementById('catsec').scrollLeft

      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },


    performSearch: function () {

      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {

        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;

      });

      console.log('search results ' + JSON.stringify(this.searchgamesdata, undefined, 2))

      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;

      } else {

        this.searchgamesdata = [];

      }

    },

    keyUpSearch: function () {

      if (this.to_search == '') {

        this.searchgamesdata = [];

      }

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },

    closeModal() {
      const modals = document.getElementsByClassName('modal-window');
      for (let i = 0; i < modals.length; i++) {
        if (!modals[i].classList.contains('d-none')) {
          modals[i].classList.add('d-none');
          break;
        }
      }
    },

    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },

    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top'
            });

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.game-icon {
  position: relative;
  width: 100%;
  height: auto;
}

.random-number {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e0f807bd;
  padding: 0px 3px;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  color: #101B21 !important;
  font-weight: 700;
}

@media (max-width: 568px) {
  .random-number {
    top: 5px;
    right: 5px;
    font-size: 10px;
  }
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.row {
  row-gap: .5em;
}

.crash-game {
  width: 100%;
}

.crash-game.more {
  grid-column: 1/span 5;
}


.fly-game {
  text-align: center;
}

.app {
  height: 100% !important;
}

.rocket-fastball {
  height: 100%;
}

h5 {
  font-size: 1rem;
}

.rocket-fastball {
  height: 100%;
  width: auto;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.featured-games-grid {
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>